import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../component/Header';
import IconArrowLeft from '../../component/SvgIcons/IconArrowLeft';
import IconArrowRight from '../../component/SvgIcons/IconArrowRight';
import IconSearch from '../../component/SvgIcons/IconSearch';
import IconView from '../../component/SvgIcons/IconView';
import TransferReceiveDetails from '../../component/TotalReceivedDetails/TransferDetails';
import TransferInventoryReceiveDetails from '../../component/TotalReceivedDetails/TransferInventoryDetails';
import api from '../utils/axios';
import { useToast } from '../utils/ToastProvider';
import './style.css';

const itemsPerPage = 20;

export default function TotalReceived() {  
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [showDetails, setShowDetails] = useState(false);
  const [activeItem, setActiveItem] = useState(null);  
  const [currentPage, setCurrentPage] = useState(1);
  const [salesInventoryDetails, setSalesInventoryDetails] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');  
  const [activeInvoiceNo, setActiveInvoiceNo] = useState(null);
  const notify = useToast();
  const userID = localStorage.getItem("UserId");
  const cmpGUID = localStorage.getItem("CmpGUID");

  // Function to get current date in YYYY-MM-DD format
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Fetch data based on current state values
  const fetchData = async () => {
    try {
      const response = await api.post('/stockReceivedetailslistdata', {
        UserID: userID,
        CmpGUID: cmpGUID,
        StartDate: startDate,
        EndDate: endDate,      
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
        
      const { data } = response;
      // Extract data from the response object
      if (data && data.GarsTallyStockReceive && data.GarsTallyStockReceive.GarsStockReceiveMasterDetail) {
        setData(data.GarsTallyStockReceive.GarsStockReceiveMasterDetail);     
        const detailsMap = data.GarsTallyStockReceive.GarsStockReceiveMasterDetail.reduce((map, item) => {
          map[item.ReceiveNo] = item.GarsStockReceiveInventoryDetail || [];
          return map;
        }, {});
      setSalesInventoryDetails(detailsMap);
      } else {
        console.error('Unexpected data format:', data);
        notify('Unexpected data format', 'error');
        setData([]);
        setSalesInventoryDetails({});
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      notify('Error fetching data', 'error');
      setData([]);
      setSalesInventoryDetails({});
    }finally {
      setLoadingData(false);
    }
  };

  const handleDateChange = (event) => {
    setStartDate(event.target.value);    
  };

  const handleendChange = (event) => {
    setEndDate(event.target.value);   
  };

  const handleCheckboxChange = (id) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };
  
  const handleSelectAll = () => {
    if (Array.isArray(data)) {
      if (selectedItems.length === data.length) {
        setSelectedItems([]); // Deselect all if currently all are selected
      } else {
        setSelectedItems(data.map((item) => item.ReceiveNo)); // Select all
      }
    } else {
      console.error('Data is not an array');
    }
  };
  
  const handleSearch = () => {    
    setLoadingSearch(true);
    setCurrentPage(1);
    setSearchQuery('');
    fetchData().finally(() => setLoadingSearch(false));
    setShowDetails(false);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleViewClick = (receiveNo) => {
    setActiveInvoiceNo(receiveNo);
    if (receiveNo === undefined || receiveNo === null) {      
      notify('Receive No is undefined or null', 'error');
      return;
    }
    const selectedItem = data.find((item) => item.ReceiveNo === receiveNo);
    setShowDetails(false);
    setActiveItem(null);
    setSalesInventoryDetails([]);
    if (selectedItem) {
      const details = selectedItem["GarsStockReceiveInventoryDetail"] || [];
      setShowDetails(true);
      setActiveItem(selectedItem);   
      setSalesInventoryDetails(details);   
    } else {
      //console.error('Item not found with Invoice No:', receiveNo);
      notify('Item not found', 'error', receiveNo);
    }
  };

  const updateSendFlags = async (sendFlag) => {
    let flagsdata = '';
    if (sendFlag === 'NO') {
      flagsdata = '0';
    } else if (sendFlag === 'YES') {
      flagsdata = '1';
    } else {
      console.error('Invalid status value');
      return;
    }
   const selectedInvoices = data.filter((item) => selectedItems.includes(item.ReceiveNo));  
    const updateData = selectedInvoices.map((Receive) => ({
      ReceiveNo: Receive.ReceiveNo,
      ReceiveDate: Receive.ReceiveDate,
      CmpGUID: cmpGUID,
      SendFlag: flagsdata
    }));
    
    try {      
      await api.post('/GarsUpdateStockReceiveprotalFlagController', {
        GarsUpdateStockReceiveProtalFlags: updateData
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const updatedData = data.map((item) =>
        //selectedItems.includes(item.ReceiveNo) ? { ...item, SendFlag: flagsdata } : item
      selectedItems.includes(item.ReceiveNo) ? { ...item, SendFlag: sendFlag.charAt(0).toUpperCase() + sendFlag.slice(1).toLowerCase()} : item
      );  
      setData(updatedData);
      notify('Send flag updated successfully', 'success');
      
    } catch (error) {
      console.error('Error updating send flags:', error.response ? error.response.data : error.message);
      notify('Error updating send flags', 'error');
    }
  };
  
  const handleSendFlagUpdate = (sendFlag) => {
    updateSendFlags(sendFlag);
  };
  
  

  const filteredData = data.filter((item) =>
    item.ReceiveNo.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getPagination = () => {
    const pages = [];
    const maxPagesToShow = 5;
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    const adjustedStartPage = Math.max(1, endPage - maxPagesToShow + 1);

    if (currentPage > 1) {
      pages.push(
        <li key="prev">
          <Link to="#" onClick={() => handlePageChange(currentPage - 1)} className="pagination-link">
            <IconArrowLeft />
          </Link>
        </li>
      );
    }

    if (adjustedStartPage > 1) {
      pages.push(
        <li key="first">
          <Link to="#" onClick={() => handlePageChange(1)} className="pagination-link">
            1
          </Link>
        </li>
      );

      if (adjustedStartPage > 2) {
        pages.push(
          <li key="ellipsis-start">
            <span className="pagination-link">...</span>
          </li>
        );
      }
    }

    for (let page = adjustedStartPage; page <= endPage; page++) {
      pages.push(
        <li key={page}>
          <Link to="#" onClick={() => handlePageChange(page)} className={`pagination-link ${currentPage === page ? 'active' : ''}`}>
            {page}
          </Link>
        </li>
      );
    }

    if (endPage < totalPages - 1) {
      pages.push(
        <li key="ellipsis-end">
          <span className="pagination-link">...</span>
        </li>
      );
    }

    if (endPage < totalPages) {
      pages.push(
        <li key="last">
          <Link to="#" onClick={() => handlePageChange(totalPages)} className="pagination-link">
            {totalPages}
          </Link>
        </li>
      );
    }

    if (currentPage < totalPages) {
      pages.push(
        <li key="next">
          <Link to="#" onClick={() => handlePageChange(currentPage + 1)} className="pagination-link">
            <IconArrowRight />
          </Link>
        </li>
      );
    }
    return pages;
  };
  return (
    <section className='eventpage'>
      <Header pageTitle="Receive Overview: By User ID and Date"/>
      <div className='pageLeftspace'>

        <div className='tableheader d-flex justify-content-between align-items-center'>
          <div className='col-6 flex-row'>
            <Link to="/dashboard" className='btn btn-danger addbutton'>
              <IconArrowLeft/> Back
            </Link>            
          </div>
     
          <div className='search-bar inputsearch'>
            <span>Select Date</span>
            <div className='datepicker'>
              <input 
                type="date" 
                value={startDate}
                onChange={handleDateChange}
                className="form-control"
              />            
            </div>
            <div className='datepicker'>
              <input 
                type="date" 
                value={endDate}
                onChange={handleendChange}
                className="form-control"
              />            
            </div>     
            <button className='btn btn-purpal' onClick={handleSearch}  disabled={loadingSearch}>              
            {loadingSearch ? (
            <div className="loader"></div>
          ) : (
            <>
               Go
            </>
          )}
            </button>                 
          </div>
        </div>
        <div className='row'>
        <div className='maindataloading d-flex gap-3 justify-content-between mb-3'>
            <div className='detailstable'>
              <div className="d-flex align-items-start justify-content-between mb-3">
                <div className='tabletitle d-flex flex-column'>
                  <h5>Receive No's Of Selected Date</h5>
                  <div className="search-status">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchQueryChange}
                      className="form-control"
                      placeholder="Search..."
                    />
                    <span>
                      <IconSearch />
                    </span>
                  </div>
                </div>
                <div className='statusbtn d-flex gap-2'>
                <button onClick={() => handleSendFlagUpdate('YES')} className='btn btn-purpal'> Status: Yes</button>
                <button onClick={() => handleSendFlagUpdate('NO')} className='btn btn-purpal'> Status: No</button>
                </div>
              </div>
              {loadingData ? (
            <div className="loader"> </div> 
          ) : (
          <>
           {data.length === 0 ? (
                <small>No data found for the selected date range</small>
              ) : (
              <table className="table">
                  <thead>
                    <tr>
                      <th width="50">
                      <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={selectedItems.length === data.length}
                        /> No.
                      </th>
                      <th>Receive No</th>
                      <th width="105">Received By Tally</th>               
                      <th className='action'>View</th>
                    </tr>
                  </thead>
                  <tbody>
                  {currentItems.map((receive, index) => (
                      <tr key={`${receive.ReceiveNo}-${index}`}>
                      <td>
                      <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={() => handleCheckboxChange(receive.ReceiveNo)}
                          checked={selectedItems.includes(receive.ReceiveNo)}
                        /> {indexOfFirstItem + index + 1}
                      </td>
                      <td>{receive.ReceiveNo}</td>
                      <td>
                        <span className="status-label">
                          {receive.SendFlag}
                        </span>
                      </td>                    
                      <td className='action'>
                      <Link
                        to="#"
                        onClick={() => handleViewClick(receive.ReceiveNo)}
                        className={receive.ReceiveNo === activeInvoiceNo ? 'active' : ''}
                      >
                        <IconView />
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>

                </table>
              )}
              </>
              )} 
              {data.length > itemsPerPage && (
                <div className="pagination-wrapper">
                  <ul className="pagination">
                    {getPagination()}
                  </ul>
                </div>
              )}
            </div>
            {showDetails && (
              <TransferReceiveDetails activeItem={activeItem} />   
            )}          
            {showDetails && (
              <TransferInventoryReceiveDetails salesInventoryDetails={salesInventoryDetails} />              
            )}         
          </div>       
        </div>  
      </div>
    </section>
  );
}
